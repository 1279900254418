import React from "react";
import PropTypes from "prop-types";
import "./Tag.less";

const Tag = ({ value }) => {
  return <span className="tag">{value}</span>;
};

Tag.protoTypes = {
  value: PropTypes.string.isRequired
};

export default Tag;
