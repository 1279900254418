import React from "react";
import SwipeableViews from "react-swipeable-views";
import "./SwipeableView.less";

const SwipeableView = ({ children }) => {
  const [view, setView] = React.useState(0);
  return (
    <div className="swipeable-view">
      <SwipeableViews
        index={view}
        resistance={true}
        disableLazyLoading={true}
        onChangeIndex={index => {
          setView(index);
        }}
      >
        {children}
      </SwipeableViews>
      <div className="swipeable-view__dots">
        {children.map((child, i) => {
          const classNames = ["swipeable-view__dot"];
          if (i === view) {
            classNames.push("swipeable-view__dot--active");
          }
          return (
            <div
              role="button"
              tabIndex="-1"
              key={`dot-${i}`}
              className={classNames.join(" ")}
              onClick={() => setView(i)}
              onKeyPress={() => setView(i)}
            />
          );
        })}
      </div>
    </div>
  );
};

SwipeableView.propTypes = {};

export default SwipeableView;
