import React from "react";
import Section from "../../organisms/Section/Section";
import PageWidth from "../../organisms/PageWidth/PageWidth";
import Button from "../../atoms/Button/Button";
import "./CallToAction.less";

const CallToAction = ({ onClick, children }) => (
  <Section>
    <PageWidth>
      <div className="call-to-action">
        <Button asLink={true} onClick={onClick}>
          {children}
        </Button>
      </div>
    </PageWidth>
  </Section>
);

export default CallToAction;
