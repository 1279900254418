import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import SwipeableView from "../SwipeableView/SwipeableView";

const ImageSet = ({ set }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(
            filter: {
              extension: { regex: "/(jpeg|jpg|gif|png)/" }
              relativePath: { regex: "/^imageSets/" }
            }
            sort: { fields: relativePath }
          ) {
            edges {
              node {
                relativePath
                childImageSharp {
                  fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      children={result => (
        <SwipeableView>
          {result.allFile.edges
            .filter(({ node }) => {
              return node.relativePath.startsWith(`imageSets/${set}/`);
            })
            .map(({ node }, index) => (
              <Img key={`image-${index}`} fluid={node.childImageSharp.fluid} />
            ))}
        </SwipeableView>
      )}
    />
  );
};

ImageSet.propTypes = {
  set: PropTypes.string.isRequired
};

export default ImageSet;
