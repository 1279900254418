import React from "react";
import PropTypes from "prop-types";
import "./Article.less";
import Button from "../../atoms/Button/Button";

const Article = ({ flipped, title, abstract, children, media }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const classes = ["article"];
  if (flipped === true) {
    classes.push("article--flipped");
  }

  return (
    <div className={classes.join(" ")}>
      <div className="article__media mobile-full-width">{media}</div>
      <div className="article__text">
        <h2 className="article__title">{title}</h2>
        {abstract && !isExpanded ? (
          <>
            {abstract}
            <Button
              variant="tertiary"
              onClick={() => setIsExpanded(true)}
              className="article__read-on"
            >
              Weiterlesen
            </Button>
            <div className="article__hidden">{children}</div>
          </>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

Article.propTypes = {
  flipped: PropTypes.bool,
  title: PropTypes.string.isRequired,
  abstract: PropTypes.node,
  media: PropTypes.node.isRequired
};

export default Article;
