import React from "react";

import Layout from "../components/organisms/Layout/Layout";
import SEO from "../components/atoms/SEO/SEO";
import Section from "../components/organisms/Section/Section";
import PageWidth from "../components/organisms/PageWidth/PageWidth";
import Video from "../components/atoms/Video/Video";
import CallToAction from "../components/molecules/CallToAction/CallToAction";
import Article from "../components/molecules/Article/Article";
import ImageSet from "../components/molecules/ImageSet/ImageSet";
import FAQ from "../components/templates/FAQ/FAQ";
import { Link } from "gatsby";
import Tag from "../components/atoms/Tag/Tag";
import OgImage from "../images/og/index.jpg";
import CartPage from "../components/organisms/Shop/CartPage/CartPage";

const IndexPage = () => (
  <>
  <CartPage />
  <Layout>
    <SEO
      title="Stadthonig: Natürlich lecker"
      description="Warum kommt der Beste Honig heutzutage aus der Stadt?"
      image={OgImage}
    />
    <Section>
      <PageWidth>
        <div style={{ marginBottom: 20 }}>
          <h1 className="no-bottom-margin">
            Der Beste Honig kommt aus der Stadt
          </h1>
          <div style={{ marginTop: 10 }}>
            <Tag value="#natürlich" />
            <Tag value="#nachhaltig" />
            <Tag value="#fair" />
          </div>
        </div>
        <Video
          src="Stadthonig-aus-Magdeburg"
          title="Woher der Honig auf stadthonig.shop kommt."
        />
      </PageWidth>
    </Section>
    <CallToAction onClick="/sorten">Honig bestellen</CallToAction>
    <Section>
      <PageWidth>
        <Article
          title="Warum ist Stadthonig besser als Honig vom Land?"
          media={<ImageSet set="stadt-garten-baeume" />}
          abstract={
            <p>
              Unsere Landwirtschaft wird größtenteils konventionell betrieben.
              Das heißt, dass Bauern Monokulturen — riesige Flächen mit nur
              einer Pflanzenart — anlegen und diese dann durch großflächigen
              Einsatz von Pestiziden vor Schädlingen schützen wollen.
            </p>
          }
        >
          <p>
            Unsere Landwirtschaft wird größtenteils konventionell betrieben. Das
            heißt, dass Bauern Monokulturen — riesige Flächen mit nur einer
            Pflanzenart — anlegen und diese dann durch großflächigen Einsatz von
            Pestiziden vor Schädlingen schützen wollen.
          </p>
          <p>
            Diese Pestizide töten dann nicht nur die unliebsamen Insekten,
            sondern auch Bienen. Außerdem kann man sie im Honig nachweisen.
          </p>
          <p>
            In der Stadt benutzen einige Gärtner sicherlich auch
            Pflanzenschutzmittel. Allerdings sind die Flächen nicht so riesig,
            die Mengen deutlich geringer und die behandelten Pflanzen für die
            Bienen teilweise uninteressant.
          </p>
          <p>
            Eine Ausnahme für guten Landhonig würde ich übrigens machen: Wenn
            Landwirte ökologisch wirtschaften und damit keine Pestizide und
            keine künstlichen Dünger einsetzen, ist auch der Honig gut.
          </p>
        </Article>
        <Article
          flipped={true}
          title="Warum ist Honig vom Imker besser als aus dem Supermarkt?"
          media={<ImageSet set="honig-vom-imker" />}
          abstract={
            <p>
              Honig im Supermarkt muss oft in großen Mengen produziert werden.
              Und dieser Honig muss dann am Besten auch immer gleich schmecken.
              Das erreicht man, indem man viele, viele Sorten zusammen kippt.
            </p>
          }
        >
          <p>
            Honig im Supermarkt muss oft in großen Mengen produziert werden. Und
            dieser Honig muss dann am Besten auch immer gleich schmecken. Das
            erreicht man, indem man viele, viele Sorten zusammen kippt.
          </p>
          <p>
            Weil wir in Europa gar nicht genug (güstigen) Honig in diesen Mengen
            produzieren, wird er aus „EU und Nicht-EU-Ländern“ zusammengekauft.
            Die Nachhaltigkeit der Landwirtschaft kaufen wir damit mit ein.
            Deshalb landen auch Nektar von genmanipulierten Pflanzen und
            Pestizide mit im Honig.
          </p>
          <p>
            Der Honig, der dabei heraus kommt, schmeckt vielleicht nicht
            besonders gut, aber dafür immer gleich.
          </p>
          <p>
            Und er bleibt teilweise ewig flüssig. Das passiert nur, wenn er
            einen hohen Wasseranteil hat.
          </p>
        </Article>
        <Article
          title="Wie läuft die Ernte von Honig ab?"
          media={<ImageSet set="honig-ernte" />}
          abstract={
            <p>
              In der Imkerei werden die Bienen zumeist in Kästen gehalten. Wenn
              die Bienen Honig sammeln, der auch geerntet werden soll, wird oben
              ein spezieller Kasten installiert in den die Königin nicht
              gelangen kann. So wird sicher gestellt, dass dort nur Honig
              eingelagert wird.
            </p>
          }
        >
          <p>
            In der Imkerei werden die Bienen zumeist in Kästen gehalten. Wenn
            die Bienen Honig sammeln, der auch geerntet werden soll, wird oben
            ein spezieller Kasten installiert in den die Königin nicht gelangen
            kann. So wird sicher gestellt, dass dort nur Honig eingelagert wird.
          </p>
          <p>
            Ist der Honig reif, verdeckeln die Bienen ihn mit einer dünnen
            Schicht Wachs. Das nennt man Deckelwachs.
          </p>
          <p>
            Bei der Ernte werden die sog. Rähmchen (Holzrahmen mit Wachs
            darinnen) entnommen. Das Deckelwachs wird entfernt und der Honig in
            einer Schleuder aus dem Rähmchen geschleudert.
          </p>
          <p>
            Über einen Ausguss läuft der frische Honig in ein mehrstufiges Sieb,
            dass grobe und feinere Reste vom Wachs entfernt.
          </p>
          <p>
            Der fertige Honig wird dann nur noch in Gläser abgefüllt. Und voilà:
            Ein reines Naturprodukt.
          </p>
          <p>
            Natürlich wird bei der nachhaltigen Ernte nicht aller Honig aus dem
            Volk entnommen. Ich entnehme auch keine Rähmchen, die neben Honig
            auch Brut (Baby-Bienen) haben.
          </p>
        </Article>
      </PageWidth>
    </Section>
    <CallToAction onClick="/sorten">Honig bestellen</CallToAction>
    <Section>
      <PageWidth>
        <Article
          flipped={true}
          title="Wie entstehen die unterschiedlichen Sorten?"
          media={<ImageSet set="honig-sorten" />}
          abstract={
            <p>
              Die Bienen sammeln in einem Umkreis von maximal 5km um ihre Beute
              herum. Die Pflanzen, die dort wachsen (und den Bienen Nektar
              geben), bestimmen quasi die Sorten, die ein Bienenvolk einträgt.
            </p>
          }
        >
          <p>
            Die Bienen sammeln in einem Umkreis von maximal 5km um ihre Beute
            herum. Die Pflanzen, die dort wachsen (und den Bienen Nektar geben),
            bestimmen quasi die Sorten, die ein Bienenvolk einträgt.
          </p>
          <p>
            Außerdem erzählen sich die Honigbienen gegenseitig von besonders
            üppigen Nektarquellen. Das führt dazu, dass sie ziemlich genau nur
            eine Sorte sammeln, wenn diese gerade blüht. Das nennt man in der
            Imkerei sortentreu. Wenn dann kurz nach einer starken Blüte geerntet
            wird, haben wir einen Honig in dem größtenteils dieselbe Sorte ist.
          </p>
          <p>
            Deshalb können wir bestimmten Sorten typischen Geschmack, Farbe und
            Festigkeit zuordnen. Stammt der Honig von diversen Sorten, die
            jeweils nicht überwiegen, dann nennt man das meist Frühlings- oder
            Sommerblüte.
          </p>
        </Article>
        <Article
          title="Ein Glas Honig vom Imker unterstützt nicht nur die Honigbienen"
          media={<ImageSet set="lobby-fuer-insekten" />}
          abstract={
            <p>
              Durch die Medien wissen wir alle mittlerweile, dass die Bienen und
              andere Insekten stark bedroht sind. Hauptgründe dafür sind die
              konventionelle Landwirtschaft und eine Milbe (speziell bei den
              Bienen), die der Mensch auf der gesamten Welt verteilt hat.
            </p>
          }
        >
          <p>
            Durch die Medien wissen wir alle mittlerweile, dass die Bienen und
            andere Insekten stark bedroht sind. Hauptgründe dafür sind die
            konventionelle Landwirtschaft und eine Milbe (speziell bei den
            Bienen), die der Mensch auf der gesamten Welt verteilt hat.
          </p>
          <p>
            Durch den Kauf einen Glases Honig vom Klein-Imker unterstützt Du die
            nachhaltige Arbeit mit Bienen.
          </p>
          <p>
            In kleinen Imkereien (weniger als 30 Völker) sind die Bienen oftmals
            wichtiger als die Ernte. Die Bienen werden hier auch selten hin- und
            hergefahren sondern bleiben an einem Ort. Das bringt weniger Ertrag,
            ist aber auch besser für die Tiere.
          </p>
          <p>
            Klein-Imker bauen Insektenhotels für andere Insekten, treten für
            einen nachhaltigeren Umgang mit der Umwelt ein und erzählen vor
            Allem Anderen von den Problemen, die die Insekten haben. Sie
            beeinflussen ihr Umfeld auf diese Weise positiv und beteiligen sich
            so am gesellschaftlichen Wandel.
          </p>
          <p>
            Wenn Du ein Glas aus ihrer Imkerei kaufst, bestärkt sie das in ihrem
            Tun.
          </p>
          <p>
            Honig vom Klein-Imker findest Du am Besten direkt in Deiner Umgebung
            durch das Schild „Honig vom Imker“, auf Nachfrage beim lokalen
            Imkerverein oder hier auf <Link to="/sorten">stadthonig.shop</Link>.
          </p>
          <p>
            Nach dem Vorbild von <em>1% for the planet</em> spende ich jedes
            Jahr 1 Prozent meines Umsatzes an anerkannte Umweltprojekte.
          </p>
        </Article>
      </PageWidth>
    </Section>
    <CallToAction onClick="/sorten">Imkerei unterstützen</CallToAction>
    <Section>
      <PageWidth>
        <FAQ
          title={
            <>
              Häufige
              <br />
              Fragen:
            </>
          }
          questions={[
            {
              title: "Wie viele Völker hast Du?",
              text:
                "Ich habe immer etwa um die 6 Völker. Im Winter verliere ich manchmal Völker. Aber im Sommer, wenn sie stark sind, vermehren sich die Völker auch."
            },
            {
              title: "Erntest Du den gesamten Honig aus den Völkern?",
              text:
                "Nein. Ich ernte nur von den starken Völkern. Und auch dort lasse ich den Bienen genug Honig. Im Herbst füttere ich sie dann außerdem mit einer speziellen Zuckerlösung, damit sie auf jeden Fall über den Winter kommen."
            },
            {
              title: "Wie erkenne ich die Sorte von Deinem Honig?",
              text: (
                <>
                  Du kannst Dir mein Video über{" "}
                  <Link to="/sorten">Honigsorten</Link> anschauen und vielleicht
                  schon alleine erkennen, was für eine Sorte Du hast. Ganz genau
                  verrät es aber das Mindest&shy;haltbarkeits&shy;datum (MHD)
                  auf dem Glas. Das kannst Du auf meiner{" "}
                  <Link to="/mhd">MHD-Seite</Link> eingeben und sie verrät Dir
                  die Sorte.
                </>
              )
            }
          ]}
        />
      </PageWidth>
    </Section>
  </Layout></>
);

export default IndexPage;
